<template>
  <div>
    <div class="row">
      <div class="col-lg-3 mt-4">
        <div v-if="loadingGroups" class="d-flex justify-content-center">
          <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <div v-else>
          <div class="mb-3">
            <input v-model="searchQuery" type="text" class="form-control" placeholder="Пошук групи...">
          </div>

          <div class="list-group">
            <div
                v-for="group in paginatedGroups"
                :key="group.id"
                class="list-group-item list-group-item-action"
                :class="{ 'active': group.id === selectedGroup?.id }"
                style="cursor: pointer"
                @click="selectGroup(group)"
            >
              <div class="d-flex justify-content-between">
                <div>
                  <h5 class="mb-1">{{ group.name }}</h5>
                </div>
                <div style="margin-left: 0.5rem">
                  <i
                      class="bi bi-envelope-exclamation-fill m-1"
                      :style="{ color: group.id === selectedGroup?.id ? '#ffffff' : 'rgba(220, 53, 69, 1)' }"
                  ></i>
                  <i
                      class="bi bi-exclamation-circle m-1"
                      :style="{ color: group.id === selectedGroup?.id ? '#ffffff' : 'rgba(220, 53, 69, 1)' }"
                  ></i>
                </div>
              </div>
            </div>
          </div>

          <div class="d-flex justify-content-between align-items-center mt-3">
            <button class="btn btn-outline-primary" :disabled="currentPage === 1" @click="previousPage">Попередня</button>
            <span> {{ currentPage }} з {{ totalPages }}</span>
            <button class="btn btn-outline-primary" :disabled="currentPage === totalPages" @click="nextPage">Наступна</button>
          </div>
        </div>
      </div>

      <div class="col-lg-9">
        <div v-if="loadingMessages" class="d-flex justify-content-center">
          <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>

        <div v-else>
          <div v-if="selectedGroup" class="card mt-4">
            <div class="card-body">
              <h5 class="card-title">Чат групи: <b>{{ selectedGroup.name }}</b></h5>
              <h5 class="card-title">Головний менеджер:
                <span v-if="selectedGroup.main_manager">{{ mainManagerName }}</span>
                <span v-else class="text-danger">Головний менеджер не вибраний</span>
              </h5>
              <button class="btn btn-outline-success" @click="editMainManager">
                {{ selectedGroup.mainManager ? 'Змінити' : 'Призначити' }}
              </button>
            </div>
            <div>
              <div v-if="showMainManagerForm" class="m-3 p-3 border">
                <select v-model="selectedMainManager" class="form-control mb-2">
                  <option disabled value="">Виберіть менеджера</option>
                  <option v-for="manager in managers" :key="manager.id" :value="manager.id">
                    {{ manager.name }} {{ manager.surname }}
                  </option>
                </select>
                <button class="btn btn-success" @click="saveMainManager">Зберегти головного менеджера</button>
                <button class="btn btn-secondary ms-2" @click="cancelEditMainManager">Скасувати</button>
              </div>
            </div>
          </div>

          <div v-if="selectedGroup" class="card mt-3">
            <div class="card-body" v-if="filteredMessages.length > 0">
              <div class="chat-messages" ref="groupMessages">
                <div
                    v-for="message in filteredMessages"
                    :key="message.id"
                    class="message d-flex"
                    :class="{ 'from-me': message.from_manager, 'from-them': !message.from_manager }"
                >
                  <div class="message-bubble media-container">
                    <div class="message-header">
                      <a class="text-muted" style="font-size: small; text-decoration: none; display: block">{{ message.sender_name }}</a>
                    </div>
                    <template v-if="message.file_link != null">
                      <template v-if="isImage(message.file_link)">
                        <img :src="message.file_link" class="img-fluid" alt="Image" @click="viewMedia(message.file_link, 'image')" style="max-width: 100%; cursor: pointer; margin-top: 10px;">
                      </template>
                      <template v-else-if="isVideo(message.file_link)">
                        <video :src="message.file_link" controls style="max-width: 100%; cursor: pointer; margin-top: 10px;"></video>
                      </template>
                      <template v-else-if="isAudio(message.file_link)">
                        <audio :src="message.file_link" controls style="max-width: 100%; cursor: pointer; margin-top: 10px;"></audio>
                      </template>
                      <template v-else-if="isOgg(message.file_link)">
                        <audio :src="message.file_link" type="audio/ogg" controls style="max-width: 100%; cursor: pointer; margin-top: 10px;"></audio>
                      </template>
                      <template v-else>
                        <a :href="message.file_link" target="_blank" class="text-primary" style="margin-top: 10px; display: inline-block;">Скачати файл</a>
                      </template>
                    </template>
                    <div  class="message-text" style="margin-top: 10px;">
                      <p v-if="message.message !== '[non-text message]'" class="mb-0">{{ message.message }}</p>
                      <a class="text-muted mt-0" style="font-size: small;text-decoration: none">{{ formatTime(message.created_at) }}</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body" v-else>
              <div class="text-center">
                <b>В цій групі, ще немає повідомлень</b>
              </div>
            </div>
          </div>

          <div v-if="selectedGroup" class="card mt-3">
            <div class="card-body">
              <form @submit.prevent="sendMessage">
                <div class="input-group">
                  <input
                      v-model="newMessage"
                      type="text"
                      class="form-control"
                      placeholder="Введіть повідомлення..."
                      required
                  />
                  <div class="btn btn-outline-secondary" @click="selectQuickMessage('Обрати шаблон')">Обрати шаблон</div>
                  <button class="btn btn-primary" type="submit">Надіслати</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getAllGroups, getManagers, getGroupMessages, sendManagerMessage, setMainManager } from '@/js/api';
import Navbar from "@/components/Navbar.vue";

export default {
  components: {
    Navbar,
  },
  data() {
    return {
      groups: [],
      selectedGroup: null,
      groupMessages: [],
      newMessage: '',
      managerId: localStorage.getItem('managerId'),
      toastTitle: '',
      toastMessage: '',
      loadingPage: true,
      loadingGroups: false,
      loadingMessages: false,
      selectedMainManager: '',
      showMainManagerForm: false,
      mainManagerName: '',
      managers: [],
      searchQuery: '',
      currentPage: 1,
      itemsPerPage: 4,
      mediaUrl: '',
      mediaType: '',
    };
  },
  async created() {
    await this.fetchGroups();
    await this.fetchManagers();
    this.connectToWebSocket();
  },
  computed: {
    filteredGroups() {
      return this.groups.filter(group => {
        const searchText = this.searchQuery.toLowerCase();
        return group.name.toLowerCase().includes(searchText);
      });
    },
    paginatedGroups() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.filteredGroups.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.filteredGroups.length / this.itemsPerPage);
    },
    filteredMessages() {
      return this.groupMessages.filter(message => {
        return (message.message && message.message.trim() !== '[non-text message]') || message.file_link != null;
      });
    },
  },
  methods: {
    async fetchGroups() {
      this.loadingGroups = true;
      try {
        const data = await getAllGroups();
        this.groups = data.data;
        this.loadingPage = false;
        console.log(this.groups);
      } catch (error) {
        console.error('Failed to load groups:', error);
      } finally {
        this.loadingGroups = false;
      }
    },
    async selectGroup(group) {
      this.selectedGroup = group;
      console.log(this.selectedGroup);

      try {
        this.loadingMessages = true;
        await this.fetchGroupMessages(group.group_id);
        const managerId = this.selectedGroup.main_manager;
        const mainManager = this.managers.find(manager => manager.id === managerId);
        if (mainManager) {
          this.mainManagerName = `${mainManager.name} ${mainManager.surname}`;
        }
        this.scrollToBottom();
      } catch (error) {
        console.error('Failed to load group messages:', error);
      } finally {
        this.loadingMessages = false;
      }
    },
    async fetchGroupMessages(groupId) {
      try {
        const data = await getGroupMessages(groupId);
        this.groupMessages = data.data;
        console.log(this.groupMessages);
        this.scrollToBottom();
      } catch (error) {
        console.error('Failed to load group messages:', error);
      }
    },
    async fetchManagers() {
      try {
        this.managers = await getManagers();
      } catch (error) {
        console.error('Error getting list of managers:', error);
      }
    },
    editMainManager() {
      this.showMainManagerForm = true;
      this.selectedMainManager = this.selectedGroup.mainManagerId || '';
    },
    async saveMainManager() {
      if (!this.selectedMainManager || !this.selectedGroup) {
        alert("Будь ласка, виберіть групу і головного менеджера.");
        return;
      }

      try {
        await setMainManager(this.selectedGroup.id, this.selectedMainManager);

        await this.fetchGroups();

        const managerId = this.selectedGroup.main_manager;
        const mainManager = this.managers.find(manager => manager.id === managerId);
        if (mainManager) {
          console.log('Шукаємо імʼя головного менеджера');
          this.mainManagerName = `${mainManager.name} ${mainManager.surname}`;
          console.log(this.mainManagerName);
        }
        this.showMainManagerForm = false;
      } catch (error) {
        console.error('Failed to save main manager:', error);
      }
    },
    cancelEditMainManager() {
      this.showMainManagerForm = false;
    },
    async sendMessage() {
      if (!this.newMessage) return;

      try {
        await sendManagerMessage(this.selectedGroup.group_id, this.newMessage, this.managerId);
        this.newMessage = '';
        await this.fetchGroupMessages(this.selectedGroup.group_id);
        this.scrollToBottom();
      } catch (error) {
        console.error('Failed to send message:', error);
      }
    },
    selectQuickMessage(template) {
      this.newMessage = template;
    },
    scrollToBottom() {
      setTimeout(() => {
        const chatMessages = this.$refs.groupMessages;
        if (chatMessages) {
          chatMessages.scrollTop = chatMessages.scrollHeight;
        }
      }, 100);
    },
    formatTime(time) {
      return new Date(time).toLocaleTimeString();
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    connectToWebSocket() {
      const socket = new WebSocket("ws://localhost:3000");
      socket.onmessage = (event) => {
        const message = JSON.parse(event.data);
      };
    },
    isImage(fileLink) {
      return /\.(jpg|jpeg|png|gif|webp)$/i.test(fileLink);
    },
    isVideo(fileLink) {
      return /\.(mp4|webm|ogg)$/i.test(fileLink);
    },
    isAudio(fileLink) {
      return /\.(mp3|wav)$/i.test(fileLink);
    },
    isOgg(fileLink) {
      return /\.(ogg)$/i.test(fileLink);
    },
    viewMedia(fileLink, type) {
      if (type === 'image') {
        window.open(fileLink, '_blank');
      }
    }
  },
};
</script>

<style scoped>
.list-group-item.active {
  background-color: #007bff;
  color: white;
}

.card {
  margin-top: 1rem;
}

.chat-messages {
  max-height: 400px;
  overflow-y: auto;
}

.message {
  display: flex;
  margin-bottom: 10px;
}

.message-bubble {
  padding: 10px;
  border-radius: 15px;
  max-width: 70%;
  background-color: #f8f9fa;
  margin-top: 10px;
}

.media-container {
  padding: 10px;
  border-radius: 15px;
  background-color: #f8f9fa;
  max-width: 70%;
  margin-bottom: 10px;
}

.from-me {
  justify-content: flex-end;
}

.from-me .message-bubble {
  background-color: #007bff;
  color: white;
  text-align: right;
}

.from-them {
  justify-content: flex-start;
}

.from-them .message-bubble {
  background-color: #f1f1f1;
  color: black;
  text-align: left;
}

.spinner-border {
  width: 3rem;
  height: 3rem;
}

.message-text {
  margin-top: 10px;
}

.message-header {
  margin-bottom: 5px;
}
</style>
