import { createRouter, createWebHistory } from 'vue-router';
import Login from '@/views/Login/Login.vue';
import Main from '@/views/Main.vue';
import Manager from "@/views/Pages/Manager.vue";
import Templates from "@/views/Pages/Templates.vue";
import Reports from "@/views/Pages/Reports.vue";

const routes = [
  {
    path: '/',
    name: 'Main',
    component: Main,
    meta: { requiresAuth: true }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { requiresAuth: false }
  },
  {
    path: '/manager',
    name: 'Manager',
    component: Manager,
    meta: { requiresAuth: true, requiresSuperuser: true }
  },
  {
    path: '/templates',
    name: 'Templates',
    component: Templates,
    meta: { requiresAuth: true }
  },
  {
    path: '/reports',
    name: 'Reports',
    component: Reports,
    meta: { requiresAuth: true, requiresSuperuser: true }
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('authToken');
  const userType = localStorage.getItem('managerType');

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!token) {
      next({ path: '/login' });
    } else if (to.matched.some(record => record.meta.requiresSuperuser)) {
      if (userType === 'superuser') {
        next();
      } else {
        next({ path: '/' });
      }
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
