<template>
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
    <div class="container-fluid">
      <<a class="navbar-brand" href="/">Expirenza chat Helper</a>>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNavDropdown">
        <ul class="navbar-nav me-auto">
          <li class="nav-item">
            <a class="nav-link" aria-current="page" href="/">Чати
              <i class="bi bi-chat-text"></i>
            </a>
          </li>
          <li v-if="isSuperuser" class="nav-item" style="cursor: default">
            <a class="nav-link" aria-current="page">|
            </a>
          </li>
          <li v-if="isSuperuser" class="nav-item ml-1">
            <a class="nav-link" aria-current="page" href="/manager">Менеджери
              <i class="bi bi-people"></i>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" aria-current="page">|
            </a>
          </li>
          <li class="nav-item ml-1">
            <a class="nav-link" aria-current="page" href="/templates">Шаблони
              <i class="bi bi-bookmark-star"></i>
            </a>
          </li>
          <li v-if="isSuperuser" class="nav-item">
            <a class="nav-link" aria-current="page">|
            </a>
          </li>
          <li v-if="isSuperuser" class="nav-item ml-1">
            <a class="nav-link" aria-current="page" href="/reports">Звіти
              <i class="bi bi-bar-chart-line"></i>
            </a>
          </li>
        </ul>
        <button class="btn btn-outline-light" @click="logout">Вийти</button>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'Navbar',
  data(){
    return {
      isSuperuser: localStorage.getItem('managerType') === 'superuser',
    };
  },
  methods: {
    logout() {
      localStorage.removeItem('authToken');
      this.$router.push('/login');
    }
  }
}
</script>

<style scoped>
</style>
